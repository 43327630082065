<template>
  <div class="info-pop-up-desktop">
    <div v-if="variant == 'geo'" class="flex-container">
      <div class="pop-up-info-block">
        <div class="small-text light-grey-text mb-20">
          {{ $t("marketplace_geo_modal.hint") }}
        </div>
        <img
          src="/icons/desktop/panda-location.png"
          alt=""
          class="panda-image mb-20"
        />
        <div
          class="big-text whitetext mb-20"
          v-html="$t('marketplace_geo_modal.title')"
        ></div>
        <div class="small-text grey-text">
          {{ $t("geo.just_click") }}
        </div>
      </div>
      <div class="buttons-container">
        <div class="pop-up-button" @click="$emit('geoConfirmed')">
          {{ $t("marketplace_geo_modal.ok") }}
        </div>
        <div class="pop-up-button outline-btn" @click="$emit('geoDenied')">
          {{ $t("marketplace_geo_modal.deny") }}
        </div>
      </div>
    </div>
    <div v-if="variant == 'geo_error'" class="flex-container">
      <div class="pop-up-info-block">
        <div class="small-text light-grey-text mb-20">
          Нам потрібно перевірити чи Ви дійсно знаходитесь в закладі
        </div>
        <img
          src="/icons/desktop/panda-location-error.png"
          alt=""
          class="panda-image mb-20"
        />
        <div class="big-text red-text mb-20">
          Ви не надали доступ до своєї геолокації
        </div>
        <div class="small-text grey-text">
          Перезавантажте сторінку та спробуйте ще раз
        </div>
      </div>
      <div class="pop-up-button">Перезавантажити сторінку</div>
    </div>
    <div v-if="variant == 'error'" class="flex-container">
      <div class="pop-up-info-block">
        <img
          src="/icons/desktop/panda-no-connection.png"
          alt=""
          class="panda-image horizontal-panda mb-20"
        />
        <div class="big-text red-text mb-20">Слабке інтернет з’єднання</div>
        <div class="small-text grey-text">
          Перевірте інтернет з’єднання та спробуйте ще раз
        </div>
      </div>
      <div class="pop-up-button">Спробувати ще раз</div>
    </div>
    <div v-if="variant == 'fatal_error'" class="flex-container">
      <div class="pop-up-info-block">
        <div class="small-text light-grey-text mb-20">
          Код помилки: {{ popupError }}
        </div>
        <img
          src="/icons/desktop/panda-error.png"
          alt=""
          class="panda-image horizontal-panda mb-20"
        />
        <div class="big-text red-text mb-20">Упс, щось пішло не так...</div>
        <div class="small-text grey-text">
          {{ $t("errors.retry") }}
        </div>
      </div>
      <div class="pop-up-button" @click="reload">
        {{ $t("cafe_closed.refresh") }}
      </div>
    </div>
    <div v-if="variant == 'fix_address'" class="flex-container">
      <div class="pop-up-info-block">
        <img
          src="/icons/desktop/panda-location.png"
          alt=""
          class="panda-image mb-20"
        />
        <div class="big-text red-text mb-20">
          {{ $t("fix_address_modal.title") }}
        </div>
        <div class="small-text grey-text">
          {{ $t("fix_address_modal.subtitle") }}
        </div>
      </div>
      <div class="pop-up-button" @click="close">OK</div>
    </div>
    <div v-if="variant == 'support'" class="flex-container">
      <div class="pop-up-info-block">
        <div class="mb-20"></div>
        <div class="small-text light-grey-text mb-30">
          {{ $t("support_modal.title") }}
        </div>
        <div class="icons-container mb-20">
          <div class="icon">
            <a href="https://t.me/@QRWaiterSupportBot" target="_blank">
              <img src="/icons/desktop/telegram-icon.png" alt="" />
              <div class="icon-text">Telegram</div>
            </a>
          </div>
          <div class="icon">
            <a href="viber://pa?chatURI=qrwaiter">
              <img src="/icons/desktop/viber-icon.png" alt="" />
              <div class="icon-text">Viber</div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div v-if="variant == 'cancel'" class="flex-container">
      <div class="pop-up-info-block">
        <p class="color-red">
          {{ $t("cancel.confirm_text") }}
          <template v-if="timeLeft">({{ timeLeft }})</template>
        </p>
        <h4 class="color-grey">{{ $t("cancel.select_reason") }}</h4>
        <CircleToggle
          :value="reason == $t('cancel.reasons.wrong_order')"
          @input="reason = $t('cancel.reasons.wrong_order')"
          class="mt-10"
          >{{ $t("cancel.reasons.wrong_order") }}</CircleToggle
        >
        <CircleToggle
          :value="reason == $t('cancel.reasons.no_time')"
          @input="reason = $t('cancel.reasons.no_time')"
          class="mt-10"
          >{{ $t("cancel.reasons.no_time") }}</CircleToggle
        >
        <CircleToggle
          :value="reason == '$'"
          @input="reason = '$'"
          class="mt-10"
          >{{ $t("cancel.reasons.custom") }}</CircleToggle
        >
        <textarea
          v-model="customReason"
          rows="3"
          :placeholder="$t('cancel.enter_reason')"
          v-if="reason == '$'"
        ></textarea>
      </div>
      <div class="buttons-container">
        <div class="pop-up-button" @click="$emit('cancel')">
          {{ $t("cancel.button_cancel") }}
        </div>
        <div class="pop-up-button red-btn" @click="confirmOrderCancel">
          {{ $t("cancel.button_continue") }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CircleToggle from "@/components/CircleToggle.vue";

export default {
  props: { variant: { type: String } },
  data() {
    return {
      variants: [
        "location-access",
        "location-error",
        "connection-error",
        "error",
        "address-error",
        "connection",
      ],

      reason: null,
      customReason: null
    };
  },
  methods: {
    close() {
      this.$emit("close");
      this.$store.commit("desktop/setInfoPopupVariant", null);
    },
    reload() {
      window.location.reload();
    },
    confirmOrderCancel() {
      if (!this.reason) return;

      if (this.reason === "$") {
        this.reason = this.customReason;
      }
      this.$emit("confirm", this.reason);
    }
  },
  computed: {
    popupError() {
      return this.$store.state.desktop.popup_error;
    },
  },
  components: {
    CircleToggle,
  },
};
</script>
<style scoped>
.info-pop-up-desktop {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 21;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.pop-up-button {
  width: 37.22222222222222vh;
  height: 5.555555555555555vh;
  background: #129b81;
  box-shadow: 0px 4px 1px rgba(0, 0, 0, 0.25);
  border-radius: 1.111vh;
  margin-top: 2.222vh;
  font-size: 1.7777777777777777vh;
  line-height: 2vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ededed;
  cursor: pointer;
}
.pop-up-info-block {
  width: 55.55555555555556vh;
  background: #1d1d1b;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2.222vh;
  padding: 2.222vh 4.44444444444444vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}
.small-text {
  font-size: 1.6666666666666667vh;
  line-height: 118.5%;
  text-align: center;
  color: #908f8d;
  width: 100%;
}
.big-text {
  font-size: 2.222vh;
  line-height: 118.5%;
  color: #ededed;
  width: 100%;
  text-align: center;
}
.white-text {
  color: #ededed;
}
.light-grey-text {
  color: #a4a3a1;
}
.grey-text {
  color: #908f8d;
}
.red-text {
  color: #e2223b;
}
.panda-image {
  width: auto;
  height: 22.666666666666668vh;
}
.horizontal-panda {
  height: 12.222222222222221vh;
}
.mb-20 {
  margin-bottom: 2.2222vh;
}
.mb-30 {
  margin-bottom: 3.3333vh;
}
.icons-container {
  display: flex;
}
.icon {
  margin-right: 4.4444vh;
  cursor: pointer;
}
.icon img {
  width: 11.11111111111111vh;
  height: 11.11111111111111vh;
  margin-bottom: 2.222vh;
}
.icon-text {
  font-size: 1.7777777777777777vh;
  line-height: 2vh;
  color: #ededed;
}
.icon:last-child {
  margin-right: 0;
}
.outline-btn {
  color: #ededed;
  background: transparent;
  border: 1px solid #ededed;
  box-shadow: none;
  margin-left: 1.65vh;
}
.red-btn {
  background: #e2223b;
  margin-left: 1.65vh;
}

.buttons-container {
  display: flex;
  width: 55.5vh;
}

textarea {
  border-radius: 10px;
  border: 1px solid #908f8d;
  outline: none;
  background: #fff;
  padding: 0.5vh 2vw;
  color: #908f8d;
  width: 100%;
  margin-top: 2.2vh;
  transition: 0.3s;
  display: block;
}

textarea:active,
textarea:hover {
  border: 1px solid darkgray;
}
</style>
