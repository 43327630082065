<template>
  <div>
    <div>
      <a
        href="https://t.me/QRWaiterSupportBot"
        v-show="false"
        ref="supportLink"
        target="_blank"
      ></a>
      <img
        src="/icons/logo.svg"
        v-if="isDarkMode"
        class="qrwaiter-small-logo"
      />
      <img
        src="../assets/logo.png"
        v-else
        class="qrwaiter-small-logo"
      />
    </div>
    <SupportModal
      @close="modal = false"
      v-if="modal"
    />
  </div>
</template>

<script>
import SupportModal from '@/components/modals/SupportModal.vue'

export default {
  data() {
    return {
      modal: false
    }
  },
  methods: {
    openSupport() {
      // this.$refs.supportLink.click();
      this.modal = true
    }
  },
  components: {
    SupportModal
  }
}
</script>

<style scoped>
img.qrwaiter-small-logo {
  height: 6.2vh;
  width: auto;
  /* 3 vh old value */
  margin: 6.2vh auto 0;
  display: block;
  opacity: 0.3;
}
</style>