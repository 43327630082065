<template>
  <div class="circle-toggle" @click="emitValue">
      <img :src="toggleImg">
      <h3 :class="{
          'circle-label': true,
          'circle-label-selected': value
        }"><slot></slot></h3>
  </div>
</template>

<script>
export default {
    props: ['value'],
    methods: {
        emitValue() {
            this.$emit('input', !this.value);
        }
    },
    computed: {
        toggleImg() {
            return !!this.value ? '/icons/circle-toggle-active.svg' : '/icons/circle-toggle.svg';
        }
    }
}
</script>

<style scoped>
.circle-toggle {
    display: flex;
    align-items: center;
    /* margin-bottom: 1.2vh; */
    cursor: pointer;
}

.circle-toggle img {
    height: 2.5vh;
    width: 2.5vh;
}

h3 {
    font-size: 2.7vh;
}

.circle-row{ 
    margin-top:1.7vh;
}

.circle-label {
    font-size: 2.5vh;
    margin-left: 1.1vh;
    margin-bottom: 0;

    color: #a4a3a1;
}

.circle-label-selected {
    color: #ededed;
}
</style>