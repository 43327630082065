<template>
  <div v-if="isMobile">
    <div class="hide-content"></div>
    <div class="support-modal-wrapper">
      <div class="support-modal-container">
        <h4 class="title">
          {{ $t("support_modal.title") }}
        </h4>
        <div class="select-support">
          <a href="https://t.me/QRWaiterSupportBot">
            <img class="lan-img" src="/icons/telegram.svg" />
            <p>Telegram</p>
          </a>
          <a href="viber://pa?chatURI=qrwaiter">
            <img class="lan-img" src="/icons/viber.png" />
            <p>Viber</p>
          </a>
        </div>
      </div>
      <button class="btn-close" @click="onClose()">
        {{ $t("support_modal.close") }}
      </button>
    </div>
  </div>
  <InfoPopupDesktop variant="support" v-else />
</template>

<script>
import InfoPopupDesktop from "@/components/desktop/InfoPopupDesktop.vue";

export default {
  methods: {
    onClose() {
      this.$emit("close");
    },
  },
  mounted() {
    this.toggleGlobalScrolling(true, true);
  },
  beforeDestroy() {
    this.toggleGlobalScrolling(false);
  },
  components: {
    InfoPopupDesktop,
  },
};
</script>

<style scoped>
.support-modal-wrapper {
  position: fixed;
  left: 0;
  right: 0;
  top: 50%;
  z-index: 15;
  margin: 0 auto;
  transform: translateY(-50%);
}

.support-modal-container {
  background: #1d1d1b;
  border-radius: 2.2vh;
  display: flex;
  padding: 2.5vh;
  flex-flow: column nowrap;
  margin: 0 auto;
  transition: 0.3s;
  overflow-y: auto;
  height: auto;
  width: 90%;
}

h4.title {
  font-size: 2vh;
  text-align: center;
  width: 100%;
  color: #a4a3a1;
  margin: 0;
}

.select-support a {
  color: #ededed;
  text-decoration: none;
}

.select-support p {
  margin: 2vh 0 0;
}

.lan-img {
  height: 12.5vh;
}

.btn-close {
  background: transparent;
  width: 90%;
  display: block;
  margin: 2.5vh auto 0;
  border: 1px solid #ededed;
  border-radius: 1.5vh;
  padding: 1.5vh 0;
  color: #ededed;
}

.select-support {
  display: flex;
  padding-top: 2vh;
  width: 100%;
  justify-content: space-around;
  align-self: center;
  color: white;
  text-align: center;
}

.hide-content {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 15;
  overflow: hidden;
  backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, 0.4);
  opacity: 1;
  top: 0;
  left: 0;
}

.close {
  width: auto;
  height: 4.2vh;
  opacity: 1;
  right: 5.5vw;
  position: absolute;
}
</style>